import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import SocialWrapper from "../../components/SocialWrapper";
import H from "../../components/Headline";
import { useAlternateLangs } from "../../components/Hreflangs.js";
import Banner from "../../components/Banner";
import EmailSignupSimplePlugin from "../../components/EmailSignupSimplePlugin";
import { experienceYears } from "../../components/Helpers";

const StyledMiddle = styled.div`
  margin: 0vmin 1vmin;
  .container {
    margin: 7vmin 0vmin;
    display: flex;
  }

  h1 {
    padding: 0;
    margin: 0rem 0rem 1rem 0rem;
  }

  .content {
    width: 60%;
    margin-right: 1rem;
  }

  .image-wrapper {
    margin: 0rem 0rem auto 0rem;
    width: 30%;

    & .gatsby-image-wrapper {
      box-shadow: 0px 0px 3px var(--foreground);
      margin-bottom: 1rem;
    }
  }
  @media all and (max-width: 540px) {
    justify-content: center;
    .container {
      flex-direction: column-reverse;
    }

    .content {
      width: 100%;
      margin-right: none;
    }

    .image-wrapper {
      margin: 1rem auto 4rem auto;
      width: 60%;
    }
  }
`;

const StyledSecondaryContent = styled.div`
  grid-area: secondary;
  background: orange;
  display: flex;

  .card {
    border-top: 1px solid var(--foreground);
    &:first-child {
      border-right: 1px solid var(--foreground);
    }
  }

  @media all and (max-width: 700px) {
    flex-direction: column;
  }
`;

// Breadcrumb data

const breadCrumbLevels = {
  Hem: "/se",
  "Om mig": "/se/om"
};

// Hreflang data

const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/about"
);

const About = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO title="Om Matthias Kupperschmidt" lang="se" alternateLangs={alternateLangs} />
      <MainContent maxWidth="700">
        <StyledMiddle>
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} className="breadcrumb" />
          <div className="container">
            <div className="content">
              <H as="h1">Hej, jag heter Matthias</H>
              <p>Jag optimerar webbplatser för Googles sökresultat och implementerar analysinställningar.</p>
              <p>
                Jag är tysk och bor i Köpenhamn, Danmark. Jag har arbetat i {experienceYears} år på flera digitala byråer innan jag började arbeta som <Link to="/se/google-analytics-frilansare">freelancekonsult för Google Analytics</Link> och SEO.
              </p>
              <p>
                Enligt flera personlighetstester är jag mycket analytisk, rationell och strukturerad, samtidigt som jag är målinriktad och pragmatisk.
              </p>
              <H as="h2">Min berättelse</H>
              <p>
                Vid 14 års ålder byggde jag min första webbplats för att byta filmer på skolgården. Det var det bästa sättet att visa min filmsamling för mina klasskamrater. Jag minns att jag skapade de första html-filerna med Microsoft Word och senare med en editor som heter "Phase5".
              </p>
              <p>
                Senare byggde jag min egen e-handelswebbshop för bordsfotboll (eller "foosball" som vi kallar det), kallad "Kickerkult". Jag var 18 år och en blivande, wannabe-professionell bordsfotbollsspelare, så det var en bra kombination av två intressen jag hade.
                <br /> Under den tiden spelade jag många turneringar i Berlin och över hela Tyskland. Samtidigt hanterade jag min webbshop, lärde mig att skicka e-postnyhetsbrev och började med SEO.
                <br />
                Till slut blev jag ganska fast vid webben och dess möjligheter och ville fokusera min karriär på den.
              </p>
              <p>
                Idag hjälper jag företag av olika storlekar att nå toppen av Googles sökrankningar och att spåra deras användarbeteende - oavsett var i världen företaget är ifrån.
              </p>
              <p>
                Dessutom skriver jag om SEO och Analysämnen på min <Link to="/se/blogg">blogg</Link>.
              </p>
              <H as="h2">
                Vilken stad är nästa Silicon Valley?
                <br />
                Din!
              </H>
              <p>
                Jag är övertygad om att man inte måste flytta till de stora, glansiga städerna för att uppnå en fantastisk karriär. Genom internet kan moderna företag, yrkesverksamma och likasinnade hitta varandra och arbeta tillsammans - oavsett geografiskt avstånd.
              </p>
            </div>
            <div className="image-wrapper">
              <Img clickable="false" src="about/matthias-kupperschmidt-about-me-portrait.jpeg" alt="matthias kupperschmidt" />
              <SocialWrapper align="center" twitter="mattzipan" linkedin="matthiaskupperschmidt" github="mattzipan" />
            </div>
          </div>
        </StyledMiddle>
      </MainContent>
      <StyledSecondaryContent>
        <Banner>
          <div className="banner__header serif headline">Låt oss hålla kontakten!</div>
          <div className="serif">
            Oroa dig inte, jag kommer knappt att mejla dig. Anmäl dig här för att vara säker på att höra när jag har något viktigt att dela med mig av.
          </div>
          <div className="emailwrapper">
            <EmailSignupSimplePlugin />
          </div>
        </Banner>
        <Banner>
        </Banner>
      </StyledSecondaryContent>
    </React.Fragment>

  </Layout>
);

export default React.memo(About);
